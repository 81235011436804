<div class="row">
	<div class="col-md-12 col-xl-12">
		<h4>Add Catalog Items from the selected Worksheet(s)</h4>
		<div class="bg-light rounded-panel p-3 mb-4">
			Please note that Worksheets may be comprised of Catalog of various templates, some of which may not be permissible via Configuration to the Record you are trying to associate them to. 
			The system will only associate the permissible via Configuration Catalog items to this Record.
		</div>
		<div>
			<rl-grid [dataSource]="dataSource"></rl-grid>
		</div>
	</div>
</div>