import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, first, isEmpty, isEqual, isUndefined, union, unionWith } from "lodash";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { EntityRelationshipDataSelectStrategy, isEntityRelationshipDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/entity-relationship-data-select.strategy";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { IndexedRowData } from "rl-common/components/grid/models/indexed-row-data";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { SearchService } from "rl-common/services/search/search.service";
import { combineLatest, of, Subscription } from "rxjs";
import { delay, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take } from "rxjs/operators";
import { EntitySearchComponent } from "../../../entities/entity-search/entity-search.component";
import { AssociationChipsComponent } from "../../../new-association-modal/new-association-wizard/select-associations/association-chips/association-chips.component";
import { CharTypeId, RelationshipIndicatorIds, SystemIndicators } from "./../../../../rl-common.consts";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { QueryUtil } from "./../../../../utils/query.util";
import { EntitySearchDataSource } from "./../../../grid/datasource/search/entity-search.datasource";
import { InputListComponent, IValidateEvent } from "./input-list/input-list.component";
import { ClipboardSelectionGridComponent } from "../../../clipboard/clipboard-selection-grid/clipboard-selection-grid.component";



@Component({
	selector: "rl-component-relationship",
	templateUrl: "./component-relationship.component.html",
	styleUrls: ["./component-relationship.component.scss"],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, InputListComponent, NgbNavOutlet, NgIf, AssociationChipsComponent, ClipboardSelectionGridComponent]
})
export class ComponentRelationshipComponent implements OnInit, OnDestroy, OnChanges {
	@Input()
	assocCharTypeId: number;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	dataSource: EntitySearchDataSource<IEntitySearchDoc, unknown>;

	@Input()
	selectedDirection: RelationshipTypes;

	@Output()
	onEntityRelationshipChange = new EventEmitter<IEntityRelationshipState<number, IEntitySearchDoc>>();

	isTablesOrRights = false;
	selectedDataFromSearch: IRecordTitle[] = [];
	private readonly _subs: Subscription[] = [];
	gridCount = 0;
	selectedInputList: IEntitySearchDoc[] = [];
	searchOptions: SearchOptions = {} as SearchOptions;
	parentCharTypeId: CharTypeId;
	parentRecordId: number;
	direction: RelationshipTypes = RelationshipTypes.Child;
	matchesParentQuery: IQueryNode;
	selectAllSearchRequestModel: ISearchRequestModel = null;

	get parentTemplateId() {
		return this._parentEntityService.templateId;
	}

	get isAssociatingChild() {
		return this.direction === RelationshipTypes.Child;
	}


	constructor(
		private readonly _parentEntityService: ParentEntityService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _searchService: SearchService
	) { }

	ngOnInit(): void {
		this.buildSearchOptions();
		this.isTablesOrRights = this.assocCharTypeId === CharTypeId.Right || this.assocCharTypeId === CharTypeId.Usage;
		if (this.charTypeId === CharTypeId.Usage && this.assocCharTypeId === CharTypeId.User) {
			const template = this._oneConfigService.getTemplate(this.charTypeId, this.templateId);
			if (template.systemIndicator === SystemIndicators.UsageDeductionsTemplate) {
				this.dataSelectStrategy.withSelectType(GridSelectType.Radio);
			}
		}


		if (isEntityRelationshipDataSelectStrategy(this.dataSelectStrategy)) {
			const selectAllSearchModelSub = combineLatest([this.dataSource.dataSelectStrategy.selectStateChange$, this.dataSelectStrategy.netChanges$])
				.pipe(
					startWith(undefined),
					filter(rd => !!rd),
					delay(0),
					distinctUntilChanged((a, b) => isEqual(a, b)),
					pairwise()
				).subscribe(([prevValue, [selectedState, nc]]) => {
					if (selectedState === undefined && nc === undefined) {
						return;
					}
					const selectAllJustSet = (!(prevValue[1]?.isAllSelected) && nc?.isAllSelected === true);
					const deselectAllJustSet = (!(prevValue[1]?.isAllDeselected) && nc?.isAllDeselected === true);
					if (selectAllJustSet || deselectAllJustSet) {
						//Deselect all should include deselecting implicit rels.
						this.selectAllSearchRequestModel = this.snapshotQuery(deselectAllJustSet);
					}
					this.emit();

				});

			const gridCountSub = this.dataSelectStrategy.count$.subscribe(c => this.gridCount = c);
			this._subs.push(gridCountSub, selectAllSearchModelSub);

		} else {
			// This warning means that the data select strategy passed into this component is not the correct type
			// Anything in this class that depends on EntityRelationshipDataSelectStrategy properties may not work properly
			console.warn(`ComponentRelationshipComponent: 'this.dataSelectStrategy' is not of type 'EntityRelationshipDataSelectStrategy.'`);
		}

		const sub2 = this.dataSource.indexedRowData$.pipe(
			filter((ird) => !!ird),
			take(1),
			filter(() => !!this.dataSelectStrategy?.defaultSelectedIds),
			switchMap(initialRowData => this.fetchDefaultSelectedDocs$(initialRowData).pipe(
				map(docs => ([initialRowData, docs] as [IndexedRowData<IEntitySearchDoc>[], IEntitySearchDoc[]]))
			))
		).subscribe(([initialRowData, defaultSelectedDocs]) => {
			if (isEmpty(defaultSelectedDocs) && this.dataSelectStrategy.isRequired && initialRowData.length === 1 && this.assocCharTypeId === CharTypeId.Property) {
				this.dataSelectStrategy.selectRow(first(initialRowData));
			} else {
				this.updateSelectedRows(initialRowData, defaultSelectedDocs);
			}
		});


		const valueChangesSub = this.dataSource.formGroup.valueChanges.subscribe((e) => this.emit());

		this._subs.push(sub2, valueChangesSub);
	}

	private snapshotQuery(forceImplicit: boolean = true) {
		const model = this.dataSource.getSelectedSearchModel()[0];

		const matchesParentQueries = [
			QueryUtil.$eq(SearchFieldNames.Relationship.childCharTypeID, this.assocCharTypeId),
			QueryUtil.$eq(SearchFieldNames.Relationship.parentCharTypeID, this.parentCharTypeId),
			QueryUtil.$eq(SearchFieldNames.Relationship.parentRecordID, this.parentRecordId),
		];
		if (!forceImplicit && (this.assocCharTypeId !== CharTypeId.User && !this.dataSource.isRelatedChild)) {
			matchesParentQueries.push(QueryUtil.$eq(SearchFieldNames.Relationship.relInd, RelationshipIndicatorIds.Default));
		}
		const queries = matchesParentQueries;
		const deselectedIds = Array.from(this.dataSelectStrategy.selectedState.deselectedIds);

		if (deselectedIds.length > 0) {
			//APP-5760 prevents case where user deselects all, then reselects and deselects a record that was removed from deselect all
			if (isUndefined(this.dataSelectStrategy.netChanges$.value.isAllDeselected) || !this.dataSelectStrategy.netChanges$.value.isAllDeselected) {
				const deselectedQuery = QueryUtil.$eq_none(SearchFieldNames.Relationship.childRecordID, deselectedIds);
				queries.push(deselectedQuery);
			}
		}
		this.matchesParentQuery = QueryUtil.$join_parent_rel(
			QueryUtil.$and(...queries)
		);
		model.query = this.matchesParentQuery;
		model.filterQueries = this.dataSource.buildFilterQueries(forceImplicit);
		model.rows = 1000;
		return cloneDeep(model);
	}


	private fetchDefaultSelectedDocs$(initialRowData: IndexedRowData<IEntitySearchDoc>[]) {
		const selectedRecordIds = this.dataSelectStrategy?.defaultSelectedIds ?? [];
		if (isEmpty(selectedRecordIds)) {
			return of(([] as IEntitySearchDoc[]));
		}
		const missingRecords = selectedRecordIds.filter(x => !initialRowData.find(rd => rd.data.recordID == x));
		const allRecordsFetched = isEmpty(missingRecords);
		if (allRecordsFetched) {
			const selectedDocs = initialRowData.filter(x => selectedRecordIds.includes(x.data.recordID)).map(x => x.data);
			return of(selectedDocs);
		}
		const query = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, selectedRecordIds);
		return this._searchService.search(this.assocCharTypeId, "", query, { start: 0, rows: selectedRecordIds.length }).pipe(
			map(x => x.documents)
		);
	}

	public updateSelectedRows(initialRowData: IndexedRowData<IEntitySearchDoc>[], defaultSelectedDocs: IEntitySearchDoc[] = []) {
		const netChanges = this.dataSelectStrategy.netChanges$.value;
		let selectedRowData = [];

		if (this.dataSelectStrategy.isAllSelected) {
			selectedRowData = initialRowData.filter(rd => {
				const excludedRecordIds = Array.from(netChanges.deletedIds);
				return !excludedRecordIds.includes(rd.data.recordID);
			});
		} else {
			const defaultSelectedRowData = this.dataSelectStrategy.defaultSelectedIds.map((recordId, index) => {
				const selectedValue = defaultSelectedDocs.find(x => x.recordID === recordId);
				return { index, rowPath: null, data: selectedValue } as IndexedRowData<IEntitySearchDoc>;
			}).filter(x => !!x.data);
			const excludedRecordIds = new Set<number>(Array.from(netChanges.deletedIds));
			const includedRecordIds = new Set<number>([...this.dataSelectStrategy.defaultSelectedIds, ...netChanges.addedIds]);
			selectedRowData = initialRowData
				.concat(defaultSelectedRowData)
				.filter(rd => includedRecordIds.has(rd.data.recordID) && !excludedRecordIds.has(rd.data.recordID));
		}
		if (selectedRowData.length > 0) {
			this.dataSource.dataSelectStrategy.selectRows(selectedRowData);
		}
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.dataSource && changes.dataSource.currentValue && changes.dataSource.currentValue instanceof AssocModuleSelectDataSource) {
			const assocDataSource = changes.dataSource.currentValue as AssocModuleSelectDataSource<any, any>;
			if (assocDataSource.direction !== undefined && assocDataSource.direction !== null) {
				this.direction = assocDataSource.direction;
			}
		}
	}

	get dataSelectStrategy() {
		return this.dataSource.dataSelectStrategy as EntityRelationshipDataSelectStrategy<IEntitySearchDoc, number, IEntitySearchDoc>;
	}

	private buildSearchOptions() {
		this.searchOptions = this.getSearchOptions();
		let parentTemplateId = null;
		if (this.assocCharTypeId === CharTypeId.Mock) {
			this.searchOptions.charTypeId = this.assocCharTypeId;
		} else if (this._parentEntityService.hasGrandParent()) {
			this.searchOptions.parentCharTypeId = this._parentEntityService.parent.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.parent.recordId;
			parentTemplateId = this._parentEntityService.parent.templateId;
			this.searchOptions.relatedCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.relatedRecordId = this._parentEntityService.recordId;
		} else {
			this.searchOptions.parentCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.recordId;
		}
		const validTemplates = this._oneConfigService.getChildAssocTemplateIds(this.searchOptions.parentCharTypeId, parentTemplateId, this.assocCharTypeId);
		this.searchOptions.filteredTemplateIds = validTemplates;
		if (this.searchOptions.parentCharTypeId === CharTypeId.Relationship && this.searchOptions.charTypeId === CharTypeId.Amount) {
			this.searchOptions.selectType = SelectType.Radio;
		}
		this.parentCharTypeId = this.searchOptions.parentCharTypeId;
		this.parentRecordId = this.searchOptions.parentRecordId;
	}

	private getSearchOptions() {
		return SearchOptionsFactory.buildTaggedRelationshipOptions(this._parentEntityService.charTypeId, this.assocCharTypeId, this.searchOptions.relatedCharTypeId);
	}

	public updateValidated(event: IValidateEvent) {
		const inputListRecordIds = event.validRecords.map(r => r.recordID);
		const indexedMatchingRows = this.dataSource.indexedRowData$?.value?.filter(rd => inputListRecordIds.includes(rd.data.recordID)) ?? [];
		const recordIds = indexedMatchingRows.map(x => x.data.recordID);
		const nonIndexedRows = event.validRecords.filter(x => recordIds.indexOf(x.recordID) < 0);
		const removedRows = event.deselectedRecords ?? [];
		removedRows.forEach(x => {
			this.dataSelectStrategy.deselectRowById(x.recordID, x);
		});
		this.dataSelectStrategy.selectRows(indexedMatchingRows);
		const dataSourceSelected = Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedIds ?? new Set<unknown>());
		this.selectedInputList = event.validRecords.filter(x => dataSourceSelected.indexOf(x.recordID) === -1);
		if (nonIndexedRows.length > 0) {
			const selectedRows: IndexedRowData<IEntitySearchDoc>[] = [];
			nonIndexedRows.forEach(row => {
				const selectedRow: IndexedRowData<IEntitySearchDoc> = {
					index: row.recordID,
					rowPath: null,
					data: row
				};
				selectedRows.push(selectedRow);
			});
			this.dataSelectStrategy.selectRows(selectedRows);
		}
	}

	public worksheetSelected(docs: IEntitySearchDoc[]) {
		this.updateValidated({
			validRecords: docs,
			validRecordsDeprecated: [],
			deselectedRecords: []
		})
	}

	public removeSelection(recordTitle: IRecordTitle) {
		// We can't pull the entity search doc from the indexed row data due to page changes or filtering
		// TODO: We need a better way to go from a selected entity to the original grid row data type
		this.dataSource.dataSelectStrategy.deselectRowById(recordTitle.recordId, recordTitle.title);
	}

	private emit() {
		const rowData = this.dataSource.indexedRowData$?.value ?? [];
		const selectedInputOffPage = this.selectedInputList.filter(r => rowData.map(rd => rd.data.recordID).includes(r.recordID));

		const dataSourceSelected = Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedIds ?? new Set<unknown>());
		this.selectedInputList = this.selectedInputList.filter(x => dataSourceSelected.indexOf(x.recordID) === -1);
		const selectedCount = this.getSelectedCount(selectedInputOffPage);
		const selectedIds = union(Array.from(this.dataSelectStrategy.selectedState.selectedIds), this.selectedInputList.map(r => r.recordID));
		const inputListSelected = this.selectedInputList.filter(x => selectedIds);
		const selectedValues = unionWith([...Array.from(this.dataSelectStrategy.selectedState.selectedValues), ...this.selectedInputList], (s, s2) => s.recordID !== s2.recordID);
		const isValid = !this.dataSource.formGroup.hasError("requiredGrid");
		const model = this.selectAllSearchRequestModel ?? this.snapshotQuery(false); // take the search model from the moment the select/deselect all was used

		this.onEntityRelationshipChange.emit({
			netChanges: this.dataSelectStrategy.netChanges$?.value,
			gridSelected: this.dataSelectStrategy.selectedState,
			gridCount: this.dataSource.rowCount$.value,
			inputListSelected,
			defaultSelectedIds: this.dataSelectStrategy.defaultSelectedIds,
			model,
			selectedValues,
			selectedIds,
			isValid,
			selectedCount,
			labelFn: (x) => x.title
		});
	}

	private getSelectedCount(selectedInputOffPage: IEntitySearchDoc[]) {
		if (!this.dataSelectStrategy.selectedState.isAllSelected) {
			return this.dataSelectStrategy.selectedState.selectedIds.size + selectedInputOffPage.length;
		} else {
			return this.dataSource.rowCount$.value - this.dataSelectStrategy.selectedState.deselectedIds.size;
		}
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
