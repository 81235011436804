<div data-cy="PanelTabs">
	<ul ngbNav #nav="ngbNav" class="nav-tabs">
		<li ngbNavItem>
			<a ngbNavLink data-cy="SearchTab">Search ({{gridCount}})</a>

			<ng-template ngbNavContent>
				<rl-entity-search [dataSource]="dataSource" [searchOptions]="searchOptions" target="_blank"
					[stretchHeight]="true" [alwaysShowHeader]="true">
				</rl-entity-search>
			</ng-template>
		</li>
		<!-- TODO: We're hiding the input list tab temporarily until we have a validation sproc that can handle associating a list of parents to a child -->
		<li ngbNavItem>
			<a ngbNavLink data-cy="InputListTab">Input IDs</a>

			<ng-template ngbNavContent>
				<rl-input-list [childCharTypeId]="assocCharTypeId" [charTypeId]="charTypeId"
					[matchesParentQuery]="matchesParentQuery" (onValidate)="updateValidated($event)"
					[includeParentRecordId]="isTablesOrRights" [isAssociatingChild]="isAssociatingChild">
				</rl-input-list>
			</ng-template>
		</li>
		<li ngbNavItem *ngIf="assocCharTypeId === 1 && charTypeId !== 10">
			<a ngbNavLink data-cy="WorksheetTab">Worksheets</a>

			<ng-template ngbNavContent>
				<rl-clipboard-selection-grid [parentCharTypeId]="charTypeId ?? parentCharTypeId" [parentTemplateId]="templateId ?? parentTemplateId" (onWorksheetSelected)="worksheetSelected($event)"></rl-clipboard-selection-grid>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>
<ng-content></ng-content>
<rl-association-chips *ngIf="dataSource" [charTypeId]="assocCharTypeId" [dataSource]="dataSource"
	[selectedInputList]="selectedInputList">
</rl-association-chips>