import { cloneDeep, Dictionary, isEqual } from "lodash";
import { BulkEditsPackage } from "rl-common/components/bulk-grid/bulk-edit.models";
import { ElementsUtil } from "rl-common/components/char-data/elements/elements.util";
import { CharDataModifyAction } from "rl-common/models/char-data-modify-action";
import { DateMathChanges } from "rl-common/models/date-math-changes";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ISaveCharDataRequest } from "rl-common/services/entity/models/i-save-char-data-request";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";

export class EditEntityUtils {

	// // TODO: Do we still need this method?
	// // Look at makeCharDataBulkEditPackage() in entity-chardata-data-change.strategy.ts, at some point we should share this logic.
	public static getEditedCharData(_oneConfigService: OneConfigService, allInitialCharData: ICharacteristicData[], allNewCharData: ICharacteristicData[], cmds: ICharacteristicMetaData[]): ISaveCharDataRequest {
		allInitialCharData = allInitialCharData ?? [];
		allNewCharData = allNewCharData ?? [];
		const deletedRecCharIds: number[] = [];
		const editedCharData: ICharacteristicData[] = [];
		const deletedDateMathCalcIds: number[] = [];
		const deletedDateMathRelIds: number[] = [];

		// process column edits and lov merging
		cmds.forEach(cmd => {
			const charId = cmd.characteristicID;
			const newCharData = allNewCharData.filter(x => x.charactersticID === charId);
			const oldCharData = allInitialCharData.filter(x => x.charactersticID === charId);
			const lovMetaData = _oneConfigService.getLovMetaData(cmd.charValueSourceID);
			let lovs = lovMetaData?.listOfValues;
			if (lovMetaData?.inactiveListOfValues !== undefined) {
				lovs = lovs.concat(lovMetaData?.inactiveListOfValues.map(x => x.cmdv));
			}

			let mergedCharData: ICharacteristicData[] = cloneDeep(oldCharData);
			mergedCharData = CharacteristicUtil.modifyCharData(cmd, mergedCharData, CharDataModifyAction.Replace, newCharData, lovs);

			if (!mergedCharData) {
				return;
			}

			// don't create empty char data when the user didn't even edit the field
			if (oldCharData.length === 0 && newCharData.length === 1 && !newCharData[0].value && !newCharData[0].valueID && !newCharData[0].ext) {
				return;
			}

			// don't update chars if there wasn't any changes
			if (mergedCharData.length === 1 && oldCharData.length === 1 &&
				mergedCharData[0].value === oldCharData[0].value &&
				(mergedCharData[0].valueID || null) === (oldCharData[0].valueID || null) && // (|| null) is to fix instance where null !== undefined.  thank you javascript
				isEqual(mergedCharData[0].ext, oldCharData[0].ext)) {
				return;
			}

			let recCharsToCreate = mergedCharData;
			if (cmd.multipleIndicator <= 0) {
				recCharsToCreate = mergedCharData.filter(x => {
					const isValueUpdated = !oldCharData?.find(y => y.valueID === x.valueID && y.value === x.value);
					const dateMathChanges = CharacteristicUtil.getDateMathChanges(x.ext?.dateMath, [DateMathChanges.Created, DateMathChanges.Updated]);
					const dateMathDeletes = CharacteristicUtil.getDateMathChanges(x.ext?.dateMath, [DateMathChanges.Deleted]);

					return isValueUpdated ||
						dateMathChanges.calcChanges.length > 0 ||
						dateMathChanges.relChanges.length > 0 ||
						dateMathDeletes && x.value; // if we remove datemath calc but there's still a date filled in
				});
			}

			const recCharsToDelete = CharacteristicUtil.getCharsToDelete(mergedCharData, recCharsToCreate, cmd, oldCharData);
			editedCharData.push(...recCharsToCreate);
			deletedRecCharIds.push(...recCharsToDelete.map(x => x.recordCharacteristicID).filter(x => recCharsToCreate.map(y => y.recordCharacteristicID).indexOf(x) === -1));
			const editElementType = ElementsUtil.mapToEditElement(cmd, true, true);
			if (recCharsToDelete && (cmd.multipleIndicator > 0 || editElementType === "dropdown" || editElementType === "money" || editElementType === "checkbox")) {
				recCharsToDelete.forEach(rc => {
					if (recCharsToCreate.filter(x => x.recordCharacteristicID === rc.recordCharacteristicID).length === 0) {
						rc.value = "";
						rc.valueID = undefined;
					}
				});
				editedCharData.push(...recCharsToDelete);
			}
			const dateMathToDelete = mergedCharData.length > 0 ? CharacteristicUtil.getDateMathChanges(mergedCharData[0].ext?.dateMath, [DateMathChanges.Deleted]) : null;
			deletedDateMathCalcIds.push(...(dateMathToDelete ? dateMathToDelete.calcChanges : []));
			deletedDateMathRelIds.push(...(dateMathToDelete ? dateMathToDelete.relChanges : []));
		});

		return {
			charDatas: editedCharData,
			deletedRecCharIds,
			deletedDateMathCalcIds,
			deletedDateMathRelIds
		} as ISaveCharDataRequest;
	}

	public static getChanges(recordId: number, modifiedCharDatas: ICharacteristicData[], cmds: ICharacteristicMetaData[], tempId: number = null): BulkEditsPackage<ICharacteristicData[]> {
		if (!cmds) {
			return;
		}
		const rowEdits: Dictionary<Dictionary<ICharacteristicData[]>> = {};
		rowEdits[recordId] = {};
		cmds.forEach(cmd => {
			rowEdits[recordId][cmd.characteristicID] = modifiedCharDatas.filter(cd => cd.charactersticID === cmd.characteristicID);
		})
		return {
			columnEditDateMathTempId: tempId,
			rowEdits: rowEdits,
			columnEdits: [],
			extraColumnEdits: [],
			extraRowEdits: {}
		};
	}
}