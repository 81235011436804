import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { cloneDeep } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntity } from "rl-common/models/i-entity";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ISaveCharDataRequest } from "rl-common/services/entity/models/i-save-char-data-request";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { ModalBuilder } from "rl-common/services/modal-builder/modal-builder";
import { HttpStatusCode } from "rl-common/services/rl-http.models";
import { SessionService } from "rl-common/services/session.service";
import { TablesService } from "rl-common/services/tables/tables.service";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { ComponentRelationshipsComponent } from "../../associations/entity-relationships/component-relationships/component-relationships.component";
import { CharDataTableComponent } from "../../char-data/char-data-table.component";
import { SelectState } from "./../../../services/entity/entity.messages";
import { OneConfigService } from "./../../../services/one-config/one-config.service";
import { IDUtil } from "./../../../utils/id.util";
import { EditEntityUtils } from "./edit-entity.utils";
import { EditWarningModalComponent } from "./edit-warning-modal/edit-warning-modal.component";
import { EditEntityCompleteEvent } from "./models/edit-entity-complete-event";

export interface CharTypeRelChange {
	charTypeId: CharTypeId;
	changeWasMade: boolean;
	wasAdded: boolean;
	wasDeleted: boolean;
}

@Component({
	selector: "rl-edit-entity",
	templateUrl: "./edit-entity.component.html",
	styleUrls: ["./edit-entity.component.scss"],
	imports: [NgIf, ComponentRelationshipsComponent, CharDataTableComponent]
})
export class EditEntityComponent implements OnInit, OnDestroy {
	@Input()
	editingEntityId: string;

	@Input()
	baseEntityId?: string;

	@Input()
	parentEntityId?: string;

	@Input()
	isRelative = false;

	@Output()
	onComplete = new EventEmitter<EditEntityCompleteEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	relationships: { [charTypeId: number]: IRecordTitle[] } = {};
	entity: IEntity;
	template: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];
	initialCharData: ICharacteristicData[];

	isSaving = false;
	charTypeId: number;
	recordId: number;
	editingRelRecId: number;
	parentRelRecId: number;
	isMultiple = true;
	requiredCharTypeDict: { [charTypeId: number]: boolean } = {};
	selectedStateDictionary: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> } = {};
	defaultSelectStateDict: { [charTypeId: number]: SelectState<number, IGridViewAssocEntityRec> } = {};
	subs: Subscription[] = [];

	private readonly _tablesAndRights = [CharTypeId.Usage, CharTypeId.Right];

	get isTablesOrRights() {
		return this._tablesAndRights.includes(this.charTypeId);
	}

	constructor(
		private readonly _parentEntityService: ParentEntityService,
		private readonly _entityService: EntityService,
		private readonly _sessionService: SessionService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _modalBuilder: ModalBuilder,
		private readonly _growlerService: GrowlerService,
		@Optional() private readonly _modDetailService: ModDetailService,
		private readonly _tableService: TablesService
	) { }

	ngOnInit() {
		const editingEntityId = IDUtil.splitEntityID(this.editingEntityId);
		this.recordId = editingEntityId.recID;
		this.charTypeId = editingEntityId.charTypeID;
		this.isMultiple = this.charTypeId !== CharTypeId.Amount;

		const getEditEntityMetaData$ = this._entityService.getEditEntityMetaData(this.editingEntityId, this.baseEntityId, this.parentEntityId);
		const getCharData$ = this._entityService.getCharData(this.editingEntityId);

		const sub = combineLatest([getEditEntityMetaData$, getCharData$])
			.pipe(
		).subscribe(([entityData, cd]) => {
			this.charData = cd;
			this.entity = entityData.entityData.entity;
			const tmd = this._oneConfigService.getTemplateMetaData(this.charTypeId, entityData.entityData.entity.templateID);
			this.template = tmd;
			this.initialCharData = cloneDeep(entityData.entityData.characteristicData);
			this.relationships = entityData.assocEntities;
			this.editingRelRecId = entityData.editingRelRecId;
			this.parentRelRecId = entityData.parentRelRecId;
			this.requiredCharTypeDict = entityData.requiredCharTypeDict;
			this.defaultSelectStateDict = entityData.defaultSelectStateDict;
			this._parentEntityService.initEditParent(this.editingEntityId, this.parentEntityId, this.baseEntityId, this.parentRelRecId, this.editingRelRecId, this.template?.templateID);
		});

		this.subs.push(sub);
	}

	save() {
		this.isSaving = true;

		const entityId = this.editingEntityId;
		const charData = cloneDeep(this.charData);
		const editedCharDataRequest = EditEntityUtils.getEditedCharData(this._oneConfigService, this.initialCharData, charData, this.template.characteristicMetaDatas);

		const relChangesWereMade = this.relChangesWereMade();
		const charDataChangesWereMade = this.charDataChangesWereMade(editedCharDataRequest);
		const changesWereMade = relChangesWereMade || charDataChangesWereMade;
		if (!changesWereMade) {
			this.onComplete.emit({
				success: true,
				entityId: this.parentEntityId,
				relWasAdded: false,
				relWasDeleted: false,
				charDataChangesWereMade: false,
				anyChangesWereMade: false,
				relChangesWereMade: false
			});
			return;
		}
		const parentEntityId = this._parentEntityService.parent ?
			IDUtil.toID(this._sessionService.divId, this._parentEntityService.parent.charTypeId, this._parentEntityService.parent.recordId) :
			null;
		const relNetChanges = this.selectedStateDictionary;
		if (this.relChangesWereMade()) {
			// TODO: refactor this so that we don't have modals opening modals
			const confirmationSub = this._modalBuilder.build<EditWarningModalComponent>(EditWarningModalComponent)
				.open(comp => {
					comp.selectedStateDictionary = this.selectedStateDictionary;
					comp.charTypeId = this.charTypeId;
					return comp.onConfirm;
				}).pipe(
					switchMap(performSave => {
						if (performSave) {
							return this.saveContinue$(entityId, editedCharDataRequest, parentEntityId);
						} else {
							this.isSaving = false;
							return of();
						}
					}),
					catchError((err) => {
						this.isSaving = false;
						throw err;
					})
				)
				.subscribe();
			this.subs.push(confirmationSub);
		} else {
			const saveSub = this.saveContinue$(entityId, editedCharDataRequest, parentEntityId).subscribe();
			this.subs.push(saveSub);
		}
	}

	private getEmitEvent(): EditEntityCompleteEvent {
		const charData = cloneDeep(this.charData);
		const editedCharDataRequest = EditEntityUtils.getEditedCharData(this._oneConfigService, this.initialCharData, charData, this.template.characteristicMetaDatas);
		const relChanges = this.getRelChanges();
		const charDataChangesWereMade = this.charDataChangesWereMade(editedCharDataRequest);
		const relChangesWereMade = relChanges.some(c => c.changeWasMade);
		const changesWereMade = relChangesWereMade || charDataChangesWereMade;
		return {
			success: true,
			entityId: this.parentEntityId,
			relWasAdded: relChanges.some(c => c.wasAdded),
			relWasDeleted: relChanges.some(c => c.wasDeleted),
			charDataChangesWereMade,
			relChangesWereMade,
			anyChangesWereMade: changesWereMade,
		};
	}

	private saveContinue$(entityId: string, editedCharDataRequest: ISaveCharDataRequest,
		parentEntityId: string = null) {
		const split = IDUtil.splitEntityID(entityId);
		const emitEvent = this.getEmitEvent();

		return this.allowAmountToSaveContinue(split.charTypeID, split.recID, editedCharDataRequest)
			.pipe(
				switchMap(allowSave => {
					if (!allowSave) {
						this.isSaving = false;
					}
					return of(allowSave);
				}),
				filter(allowSaveToCont => allowSaveToCont),
				switchMap(() => this._entityService.saveEntity(split.charTypeID, split.recID, editedCharDataRequest, this.selectedStateDictionary, parentEntityId)
					.pipe(
						catchError(e => {
							if (e.status === HttpStatusCode.Conflict) {
								this._growlerService.error().growl(e?.error?.message);
								return of(null);
							}
							throw e;
						}),
						tap((result) => {
							this.onComplete.emit(emitEvent);
							this.isSaving = false;
						})
					))
			);
	}

	private allowAmountToSaveContinue(charTypeId: number, recordId: number, editedCharDataRequest: ISaveCharDataRequest): Observable<boolean> {
		if (charTypeId === CharTypeId.Amount && this._modDetailService) {
			const parentCharTypeId = this._modDetailService.entity.charTypeId;
			const amountCurrency = CharacteristicUtil.getLocalCurrency(charTypeId, this.template.characteristicMetaDatas, editedCharDataRequest.charDatas);
			const amountCurrencySymbol = amountCurrency[0];
			const amountCurrencyId = amountCurrency[1];
			if (parentCharTypeId == CharTypeId.Invoice) {
				if (amountCurrencySymbol) {
					const json = `[{"recID":${recordId},"curSym": "${amountCurrencySymbol}"}]`;
					return this._entityService.checkAmountInvoiceCurrencyMisMatch(charTypeId, json)
						.pipe(
							map(result => {
								if (result && result.length > 0 && amountCurrencySymbol !== result[0].invoiceCurSym) {
									this._growlerService.error("Error").growl(`Amount's selected Currency ${amountCurrencySymbol} does not match with associated Invoice Currency ${result[0].invoiceCurSym}`);
									return false;
								}
								return true;
							})
						);
				}
			} else if (parentCharTypeId == CharTypeId.Transaction && amountCurrencyId) {
				return this._tableService.canCreateChildAmount(this._parentEntityService.parent?.parent?.recordId, amountCurrencyId)
					.pipe(
						tap(result => {
							if (!result) {
								this._growlerService.error().growl("Amounts of mixed currencies not allowed under single table row");
							}
						})
					);
			}
		}
		return of(true);
	}

	changesWereMade(editedCharDataRequest: ISaveCharDataRequest) {
		return this.relChangesWereMade() || this.charDataChangesWereMade(editedCharDataRequest);
	}

	relChangesWereMade() {
		return Object.values(this.selectedStateDictionary)
			.some(state => {
				if (state.netChanges.isAllDeselected) {
					return state.defaultSelectedIds.length > 0;
				} else if (!state.netChanges.isAllSelected) {
					return state.netChanges?.addedIds?.size > 0 || state.netChanges?.deletedIds?.size > 0;
				}
				const wasAllSelectedBefore = state.defaultSelectedIds.length === state.gridCount;
				return !wasAllSelectedBefore || state.netChanges.deletedIds.size > 0;
			});
	}

	getRelChanges(): CharTypeRelChange[] {
		return Object.entries(this.selectedStateDictionary)
			.map(kvp => {
				const ct = +kvp[0];
				const state = kvp[1];
				let wasAdded = false;
				let wasDeleted = false;
				if (state.netChanges.isAllDeselected) {
					wasAdded = state.defaultSelectedIds.length > 0;
				} else if (!state.netChanges.isAllSelected) {
					wasAdded = state.netChanges?.addedIds?.size > 0;
					wasDeleted = state.netChanges?.deletedIds?.size > 0;
				} else {
					const wasAllSelectedBefore = state.defaultSelectedIds.length === state.gridCount;
					wasDeleted = !wasAllSelectedBefore || state.netChanges.deletedIds.size > 0;
				}
				return {
					charTypeId: ct,
					changeWasMade: wasAdded || wasDeleted,
					wasAdded,
					wasDeleted
				}
			});
	}

	charDataChangesWereMade(editedCharDataRequest: ISaveCharDataRequest) {
		return editedCharDataRequest.charDatas.length > 0 || editedCharDataRequest.deletedRecCharIds.length > 0 ||
			editedCharDataRequest.deletedDateMathCalcIds.length > 0 || editedCharDataRequest.deletedDateMathRelIds.length > 0;
	}

	cancel() {
		this.onCancel.emit();
	}

	updateRelationshipStates(states: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> }) {
		this.selectedStateDictionary = states;
	}

	get areRelsValid() {
		const invalidRequired = Object.keys(this.requiredCharTypeDict)
			.filter(charTypeId => this.selectedStateDictionary[charTypeId]?.isValid === false);
		return invalidRequired.length === 0;
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
