import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { NgIf } from "@angular/common";

@Component({
    selector: "rl-edit-warning-modal",
    templateUrl: "./edit-warning-modal.component.html",
    styleUrls: ["./edit-warning-modal.component.scss"],
    imports: [NgIf]
})
export class EditWarningModalComponent implements OnInit {
	@Input()
	selectedStateDictionary: { [charTypeId: number]: IEntityRelationshipState<number, unknown> } = {};

	@Input()
	charTypeId: CharTypeId;

	@Output()
	onConfirm = new EventEmitter<boolean>();

	warningMessage: string;
	constructor() { }

	ngOnInit(): void {
		this.warningMessage = this.getWarningMessage();
	}

	public decline() {
		this.onConfirm.emit(false);
	}

	public accept() {
		this.onConfirm.emit(true);
	}

	private getWarningMessage(): string {
		const catalogState = this.selectedStateDictionary[CharTypeId.Property];
		const contactState = this.selectedStateDictionary[CharTypeId.User];
		const projectState = this.selectedStateDictionary[CharTypeId.Project];
		const mockState = this.selectedStateDictionary[CharTypeId.Mock];

		// TODO: Refactor this to stop concatenating strings
		let warningMsg = "";
		warningMsg = "<h4>Are you sure you want to:</h4><br /><br />";
		warningMsg += this.getAddedMessage(catalogState, "Catalog Items");
		warningMsg += this.getAddedMessage(contactState, "Contact Items");
		warningMsg += this.getAddedMessage(projectState, "Project Items");
		warningMsg += this.getAddedMessage(mockState, "Relative Items");

		if (catalogState?.netChanges?.deletedIds?.size > 0) {
			warningMsg += this.getDeletedMessage(catalogState, "Catalog Items");
			if (this.charTypeId === CharTypeId.Usage) {
				const removedTitles = Array.from(catalogState?.netChanges?.deletedValues.values())
					.map(val => catalogState.labelFn(val))
					.filter(title => !!title);
				const titlesStr = ConstUtils.stringifyLabels(removedTitles);
				warningMsg += `<p>This will remove <strong>${titlesStr}</strong> from the Table and its associated Amounts.</p>`;
			}
		}

		if (contactState?.netChanges?.deletedIds?.size > 0) {
			warningMsg += this.getDeletedMessage(contactState, "Contact Items");
		}

		if (projectState?.netChanges?.deletedIds?.size > 0) {
			warningMsg += this.getDeletedMessage(projectState, "Project Items");
		}

		if (mockState?.netChanges?.deletedIds?.size > 0) {
			warningMsg += this.getDeletedMessage(mockState, "Relative Items");
		}

		return warningMsg;
	}

	private getDeletedMessage(state: IEntityRelationshipState<number, unknown>, display: string) {
		if (!state?.netChanges) {
			return "";
		}
		let size = 0;
		const netChanges = state.netChanges;
		if (!netChanges.isAllSelected) {
			size = netChanges?.deletedIds?.size;
		} else {
			const deletedOriginalIds = Array.from(netChanges.deletedIds).filter(id => state.defaultSelectedIds.includes(id));
			size = deletedOriginalIds.length;
		}
		return this.deleteMessage(size, display);
	}

	private getAddedMessage(state: IEntityRelationshipState<number, unknown>, display: string) {
		if (!state?.netChanges) {
			return "";
		}
		let size = 0;
		const netChanges = state.netChanges;
		if (!netChanges.isAllSelected) {
			size = netChanges?.addedIds?.size;
		} else if (netChanges.isAllSelected) {
			size = state.selectedCount - state.defaultSelectedIds.length;
		} else {
			const deletedOriginalIds = Array.from(netChanges.deletedIds).filter(id => state.defaultSelectedIds.includes(id));
			size = state.netChanges.addedIds.size - deletedOriginalIds.length;
		}
		if (size <= 0) {
			return "";
		}
		return this.addMessage(size, display);
	}

	private addMessage(size: number, display: string) {
		return `Add ${size} ${display}<br /><br />`;
	}

	private deleteMessage(size: number, display: string) {
		return `Remove ${size} ${display}<br /><br />`;
	}

}
