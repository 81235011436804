<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li ngbNavItem>
		<a ngbNavLink>Search ({{gridCount}})</a>

		<ng-template ngbNavContent>
			<rl-entity-search [dataSource]="dataSource" [searchOptions]="searchOptions" target="_blank"
							  [stretchHeight]="true" [alwaysShowHeader]="true">
			</rl-entity-search>
		</ng-template>
	</li>
	<li ngbNavItem>
		<a ngbNavLink>Input IDs</a>

		<ng-template ngbNavContent>
			<rl-input-list [childCharTypeId]="assocCharTypeId" [charTypeId]="charTypeId"
						   [matchesParentQuery]="matchesParentQuery" (onValidate)="updateValidated($event)"
						   [includeParentRecordId]="isTablesOrRights"></rl-input-list>
		</ng-template>
	</li>
	<li ngbNavItem *ngIf="assocCharTypeId === 1">
		<a ngbNavLink data-cy="WorksheetTab">Worksheets</a>

		<ng-template ngbNavContent>
			<rl-clipboard-selection-grid [parentCharTypeId]="charTypeId" [parentTemplateId]="parentTemplateId" (onWorksheetSelected)="worksheetSelected($event)"></rl-clipboard-selection-grid>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="nav"></div>
<ng-content></ng-content>
<rl-association-chips *ngIf="dataSource" [charTypeId]="assocCharTypeId" [dataSource]="dataSource">
</rl-association-chips>