import { Component, DestroyRef, effect, EventEmitter, Injector, Input, OnDestroy, Output } from "@angular/core";
import { CommonGridDataSource } from "../../grid/datasource/common-grid.datasource";
import { IWorksheet, IWorksheetRecords } from "../../../services/clipboard/clipboard.models";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { Observable, Subscription } from "rxjs";
import { ClipboardService } from "../../../services/clipboard/clipboard.service";
import { GridDataSourceBuilder } from "../../grid/datasource/builders/grid-datasource-builder";
import { map } from "rxjs/operators";
import { GridColumn } from "../../grid/models/grid-column";
import { GridSelectType } from "../../grid/models/grid-select-type";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ISearchRequestOptions } from "../../../models/i-search-request-options";
import { QueryUtil } from "../../../utils";
import { SearchFieldNames } from "../../entities/entity-search/query.models";
import { SearchService } from "../../../services/search/search.service";
import { IEntitySearchDoc } from "../../../models/i-entity-search-doc";
import { AssociationService } from "../../../services/associations/association.service";

@Component({
  selector: "rl-clipboard-selection-grid",
  imports: [ GridTableComponent ],
  templateUrl: "./clipboard-selection-grid.component.html",
  styleUrl: "./clipboard-selection-grid.component.scss"
})
export class ClipboardSelectionGridComponent implements OnDestroy {
	subs: Subscription[] = [];
	dataSource: CommonGridDataSource<IWorksheet<IWorksheetRecords>>;

	worksheets$: Observable<IWorksheet<IWorksheetRecords>[]>;

	@Input()
	parentCharTypeId: number;

	@Input()
	parentTemplateId: number;

	@Output()
	onWorksheetSelected = new EventEmitter<IEntitySearchDoc[]>();

	constructor(
		private readonly _clipboardService: ClipboardService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _searchService: SearchService,
		private readonly _injector: Injector,
		private readonly _destroyRef: DestroyRef,
		private readonly _associationService: AssociationService) {

		const selectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<IWorksheet<IWorksheetRecords>, string, IWorksheet<IWorksheetRecords>>(row => row.clipboardId, row => row)
			.setSelectAllEnabled(false)
			.withSelectType(GridSelectType.Radio);

		this.dataSource = this._gridDataSourceBuilder.commonGridDataSource<IWorksheet<IWorksheetRecords>>(row => row.clipboardId)
			.setColumns(this.columns)
			.withDataSelectStrategy(selectStrategy)
			.withFetchFn((ds) => {
				return this._clipboardService.getRecordWorksheets(1).pipe(
					map((results) => {
						return { rowCount: results.length, rowData: results }
					})
				);
			});

		const selectSub = selectStrategy.selectStateChange$.subscribe(state => {
			const selected = Array.from(state.selectedValues);
			if (selected && selected.length === 1) {
				this.selectWorksheet(selected[0].data.recordIds);
			}
		});
		this.subs.push(selectSub);

		effect(() => {
			this.dataSource.fetchRows()
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe();
		}, { injector: this._injector });
	}

    ngOnDestroy(): void {
		this.subs.forEach((sub) => {
			sub.unsubscribe();
		})
	}

	private selectWorksheet(recordIds: number[]) {
		const query = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, recordIds);

		if (this.parentCharTypeId && this.parentTemplateId) {
			this._associationService.validateInputList(this.parentCharTypeId, this.parentTemplateId, 1, recordIds, [],
				null, false, query)
				.subscribe((validationResult) => {
					this.onWorksheetSelected.emit(validationResult.valid);
				});
		} else {
			const options: ISearchRequestOptions = {
				rows: recordIds.length,
				start: 0,
				sortDir: 1,
				sortField: "title",
				facetFields: []
			};

			this._searchService.search(1, "", query, options)
				.subscribe((res) => {
					this.onWorksheetSelected.emit(res.documents);
				})
		}
	}

	getRecordWorksheets() {
		this.worksheets$ = this._clipboardService.getRecordWorksheets(1);
	}

	private readonly columns: GridColumn<IWorksheet<IWorksheetRecords>>[] = [
		{
			key: "title",
			headerName: "Worksheet Name",
			renderer: "text",
			sortKey: "title",
			width: "auto",
			getCellData: (x) => x.title
		},
		{
			key: "items",
			headerName: "# Catalog Items",
			renderer: "text",
			sortKey: "title",
			width: "auto",
			getCellData: (x) => x.data.recordIds.length
		},
		{
			key: "created",
			headerName: "Created",
			renderer: "date_time",
			sortKey: "created",
			width: "auto",
			getCellData: (x) => x.created
		},
		{
			key: "updated",
			headerName: "Last Updated",
			renderer: "date_time",
			sortKey: "updated",
			width: "auto",
			getCellData: (x) => x.updated
		}
	]
}
